
.App-contentgrid {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-gap: 10px;
    overflow-y: auto;
}
  
.App-toparea {
    display: grid;
}
  
.App-tablearea {
    display: grid;
    grid-template-rows: auto auto 1fr;
    overflow-x: clip;
    overflow-y: auto;
}
  
.App-container {
    display: grid;
    grid-template-columns: 16rem 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: 
      "navheader header"
      "nav content";
    height: 100vh;
}
  
header {
    grid-area: header;
    padding: 5px;
    background: colorNeutralBackground1;
}
navheader {
    grid-area: navheader;
    padding: 5px;
    background: colorNeutralBackground1;
}
nav {
    grid-area: nav;
    padding: 5px;
    background: colorNeutralBackground1;
    overflow-y: hidden;
}
navfooter {
    grid-area: navfooter;
    padding: 5px;
    background: colorNeutralBackground1;
}
main {
    display: grid;
    grid-area: content;
    grid-template-rows: auto 1fr;
    padding: 10px;
    background: colorNeutralBackground1;
    overflow-y: auto;
    grid-gap: 10px;
}
  
aside {
    grid-area: sidebar;
    padding: 2rem;
    background: colorNeutralBackground1;
}
footer {
    grid-area: footer;
    background: colorNeutralBackground1;
}
  
@media (max-width: 1024px) {
    .container {
      grid-template-columns: 1fr;
      grid-template-rows: auto minmax(5rem,auto) 1fr minmax(5rem, auto) auto;
      grid-template-areas: 
        "header"
        "nav"
        "content"
        "sidebar"
        "footer";
    }
 }
  
.Spinner {
    pointer-events: none;
}
  
@media (prefers-reduced-motion: no-preference) {
    .Spinner-icon {
        animation: Spinner-icon-spin infinite 10s linear;
    }
}
  
@media (prefers-reduced-motion: no-preference) {
    .Spinner-icon-small {
        animation: Spinner-icon-spin infinite 10s linear;
    }
}
  
@keyframes Spinner-icon-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}